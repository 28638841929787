import * as React from 'react';
import { useEffect } from 'react'

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';

const theme = createTheme();

export default function Album() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">


                        <Typography variant="h5" align="center" color="text.secondary">
                            <div className='homepage_title'>
                                Hello! My name is Randy Ryan. I'm a graduate from Wentworth Institute of Technology with a bachelors degree in Computer Science and a minor in the Applied Mathematics field.

                                <br /><br />
                                Please feel free to email me and get in touch
                                directly through the form on the contact page.

                            </div>

                        </Typography>

                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="md">
                    <Link to="/contact">
                        <Button>

                            <Card >
                                <CardMedia
                                    component="img"
                                    className="homepage_card"
                                    image="https://images.unsplash.com/photo-1528747045269-390fe33c19f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Contact me
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Button>
                    </Link>
                    <Link to="/about">
                        <Button>
                            <Card >
                                <CardMedia
                                    component="img"
                                    className="homepage_card"
                                    image="https://images.unsplash.com/photo-1608914890779-a476a67150d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        About me
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Button>
                    </Link>
                    <Link to="/projects">
                        <Button>
                            <Card >
                                <CardMedia
                                    component="img"
                                    className="homepage_card"
                                    image="https://images.unsplash.com/photo-1527219525722-f9767a7f2884?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80"
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        My Projects
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Button>
                    </Link>
                    <Link to="/resume">
                        <Button>
                            <Card>
                                <CardMedia className="homepage_card"
                                    component="img"

                                    image="https://images.unsplash.com/photo-1586281380349-632531db7ed4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        My Resume
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Button>

                    </Link>
                    <Link to="/lacrosse">
                        <Button>
                            <Card >

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <CardMedia className="homepage_card"
                                        component="img"
                                        image="https://images.unsplash.com/photo-1520298064646-747b5051dbb2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2348&q=80"
                                        alt="lacrosse"
                                    />
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Lacrosse
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Button>
                    </Link>
                </Container>
            </main>

        </ThemeProvider>
    );
}
