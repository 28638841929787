import React, { useEffect, } from 'react'
import Album from "./Album"
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Album />
    </div>
  )
}

