import React, { useEffect } from 'react'

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <h1 className="header_title">About me</h1>
      <div className="paragraph">
        Before starting high school, I gained a passion for computers. I grew up playing a lot of video games, the main game being Minecraft. I used to host my own
        Minecraft server from home around the age of 12 and actually made some money from player donations! I was
        playing around with writing code in Java without even knowing it. This part of my life was essentially
        the "push" for me in deciding that I wanted to study computer science in college.
        On another note, I'm a big family guy! I have 3 siblings and I'm an uncle to four niblings (neices and nephews);
        Wyatt/Maddie, and Liam/Olivia.
      </div>

      <div className="paragraph">
        Liam is my oldest nephew and I am closer in age with him than I am with my brother, his father. A few years ago,
        he was diagnosed and has been battling with a rare auto-immune disease called Juvenile Dermatomyositis (JDM).
        His website is <a href="https://curejm4liam.com/">linked here</a>, along with the <a href="https://www.curejm.org">Cure JM</a> website.
        These websites are very informative on what thisdisease is all about.
        <br /><br />
        Liam also has a new childrens book out called "Monstah Potion." This book was a family project with Liam as creator,
        his father as publisher, his grandmother as writer, and my father, his grandfather as illustrator. Copies of the book are currently sold out,
        but will be available for purchase soon.
      </div>


    </div>
  )
}
