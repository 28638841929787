import './App.css';
import Home from './Home'
import Contact from './Contact';
import Projects from './Projects';
import About from './About';
import Resume from './Resume';
import Lacrosse from './Lacrosse';
import Sidebar from './NavBar'
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <a color="inherit" href="https://randysryan.com">
        randysryan.com
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function App() {
  return (
    <div className="App">
      <Sidebar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/resume" element={<Resume />} />
        <Route exact path="/lacrosse" element={<Lacrosse />} />
      </Routes>
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Randall S. Ryan
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          <a href="https://linkedin.com/in/randyryan5656">LinkedIn</a>

        </Typography>
        <Copyright />
      </Box>
    </div>
  );
}

export default App;
