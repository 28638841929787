import React, { useEffect, } from 'react'

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <h1 className="header_title">Projects</h1>

      {/* <div className='github'>
        <a href="https://github.com/Randall-Ryan" ><h2 >My GitHub</h2></a>
      </div> */}
      <div className="paragraph">
        <a href="https://tracking-project-ab1fc.web.app/login" ><h2>My Tracker</h2></a>

        My Tracker is a  project of mine revolved around creating a personal "my fitness pal." This is designed
        to track diet, daily exercise, tasks, and more. I'm building this project using
        HTML, CSS, and JavaScript - Check it out using the "My Tracker" link above!
      </div>

      <div className="paragraph">
        <a href="https://webdevo-ddf37.web.app/index.html" ><h2>BusyQuizzy</h2></a>

        BusyQuizzy is a quiz hosting website, with the purpose of being a newly found source of fun and
        entertainment. This project was developed
        using HTML/CSS/JavaScript. The goal behind the project was to create an engaging website for users to
        create and take quizzes. All quizzes are
        multiple choice questions with four or less possible answers, one of these answers being the correct
        one. BusyQuizzy offers a user functionality
        for someone to sign up and create an account.
        <br /><br />
        Users are able to earn points by answering questions correctly. Points are used to purchase Quiz
        Helpers, which are used to assist in choosing
        the correct answer by removing one wrong answer. Users must have an account to view their balance and
        make any sort of purchases.
      </div>
      <div className="paragraph">
        <a href="https://play.google.com/store/apps/details?id=com.swiftDevelopers.codersPlatform" ><h2>Coders Platform</h2></a>
        Plenty of individuals come up with ideas every day for an app, a project, a device, etc.
        The purpose of this app is to provide a social media platform that connects creative minds
        with interested, freelancing, developers. Coders Platform was developed in Android Studio
        using Java for back end purposes and XML for front end purposes. The app is currently
        available for download on the google playstore!
        <br /><br />
        Users are able to create a profile where they can register with a profile picture,
        username, and email. With the creation of an account, users can log in and begin to create
        posts that are shown in the public feed. Users can also search for other accounts within the
        system and view that account and their posts. All account pages, including "my account," display
        all posts from that users account.
      </div>
    </div>
  )
}
