import React, { useRef, useEffect, } from 'react';
import emailjs from '@emailjs/browser';
import myEmailObject from './EmailJS';
import { TextField, Button } from '@mui/material';

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    console.log(
      form.current
    )
    emailjs.sendForm(myEmailObject['MY_SERVICE_KEY'], myEmailObject['MY_TEMPLATE_ID'], form.current, myEmailObject['MY_PUBLIC_KEY'])
      .then((result) => {
        console.log(result.text);
        alert("Email successfully sent!")
      }, (error) => {
        console.log(error.text);
        alert("Error sending email")

      });
  };

  return (
    <div>
      <h1 className="header_title">Contact me</h1>
      <br /><br /><br />
      <form ref={form} onSubmit={sendEmail} className="contact_background">
        <div className='contact_form'>
          <h2>Please fill out the form below to send me a direct message</h2>
          <br /> <br />
          <TextField label="Name" type="text" name="user_name" />
          <br /> <br />
          <TextField label="Email" type="email" name="user_email" />
          <br /><br />
          <br /><br />
          <TextField className="message_box" label="Message" name="message" />
          <br /><br />
          <Button variant="contained" type="submit"> Send </Button>
        </div>
      </form>
    </div>
  );
};