import React, { useEffect } from 'react'

import { Link } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';

export default function Resume() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <h1 className="header_title">My Resume</h1>
            <div className='paragraph'>
                <h2>
                    Download my resume <Link to="/Images/RandallRyanResume.docx" target="_blank" download>here</Link> (.docx)
                    or <Link to="/Images/RandallRyanResume.pdf" target="_blank" download>here</Link> (.pdf).
                </h2>
                <br /> <br /> <br />
                <Card >
                    <CardMedia
                        component="img"
                        className="resume_card"
                        image="/Images/resum.png"
                        alt="resume"
                    />
                </Card>

            </div>
            <div className='paragraph'>
                I've worked a handful of jobs in the past, gaining the following range of job titles: Data Management Technician, Full Stack Developer,
                IT Help Desk Technician, Bouncer, Concrete Design Specialist, and, my favorite of them all, Ice Cream Scooper.
                In my most recent work experience, I spent a semester abroad interning as a full stack developer for
                a startup company, GoWith, and lived in Jerusalem from Sept. 2020 - Dec. 2020.
                <br /><br />
                I am looking to officially begin a career in the field of development. My ideal position would be a
                full stack developer, however, I am mainly looking to gain additional experience for the back end side of a
                project.
            </div>
        </div>
    )
}
