
import React, { useState } from "react";
import { Link } from "react-router-dom";
function Sidebar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <nav className={sidebar ? "sidebar active" : "sidebar"}>
      <button className="hamburger" type="button" onClick={showSidebar}>
        <div></div>
      </button>
      <ul onClick={showSidebar}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About me</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/resume">My Resume</Link></li>
        <li><Link to="/lacrosse">Lacrosse</Link></li>
        <li><Link to="/contact">Contact me</Link></li>

      </ul>
    </nav>
  );
}

export default Sidebar;
// export default function NavBar() {
//   return (
//     <div>

//       <li><Link to="/">Home</Link></li>
//       <li><Link to="/contact">Contact</Link></li>
//       <li><Link to="/about">About</Link></li>
//       <li><Link to="/projects">Projects</Link></li>
//       <li><Link to="/resume">Resume</Link></li>
//       <li><Link to="/lacrosse">Lacrosse</Link></li>
//     </div>
//   )
// }
