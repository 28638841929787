import React, { useEffect, } from 'react'
import LazyIframe from './LazyIframe';
export default function Lacrosse() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <h1 className="header_title">Lacrosse</h1>
            <div className='paragraph'>
                <h2>
                    Check out my interview for CCC Person of the Week <a href="https://www.cccathletics.com/sports/general/2020-21/PersonoftheWeek/20201123RRyan">here</a>.
                </h2>
            </div>
            <LazyIframe className="stick_trick_vid" title="stick_trick_vid_1" url="https://www.youtube.com/embed/olz4c-x_jvc" ></LazyIframe>

            <div className='paragraph'>
                I've been playing lacrosse since first grade and I'm extra thankful to have had the opportunity
                to compete in the NCAA throughout my college career. Aside from playing goalie, I enjoy practicing and
                perfecting stick tricks in my free time. <br /><br />

                Below are a couple videos that I posted on YouTube showing off a few of these stick tricks. I've also been
                featured on big name lacrosse
                Instagram pages like @lacrossenetwork, @laxallstars, and more!
            </div>
            <LazyIframe className="stick_trick_vid" title="stick_trick_vid_2" url="https://www.youtube.com/embed/wae5ZGp-v3w" ></LazyIframe>
            <LazyIframe className="stick_trick_vid" title="stick_trick_vid_3" url="https://www.youtube.com/embed/npn1Jncglu8"></LazyIframe>

        </div>
    )
}
